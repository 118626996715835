<script lang="ts" setup>
const props = defineProps<{
  html: string
  allowedTags?: string[]
  byTagName?: keyof typeof tagNamePresets
}>()

const inlineTags = [
  'a',
  'abbr',
  'b',
  'bdi',
  'bdo',
  'br',
  'cite',
  'code',
  'data',
  'dfn',
  'em',
  'i',
  'img',
  'kbd',
  'mark',
  'q',
  'rp',
  'rt',
  'ruby',
  's',
  'samp',
  'small',
  'span',
  'strong',
  'sub',
  'sup',
  'svg',
  'time',
  'u',
  'var',
  'wbr',
]

const tagNamePresets = {
  h1: inlineTags,
  h2: inlineTags,
  h3: inlineTags,
  h4: inlineTags,
  h5: inlineTags,
  span: inlineTags,
  small: inlineTags,
  // TODO: Add your tag if needed
}

const sanitizeHtml = (await import('sanitize-html')).default

const sanitizedHtml = computed(() => sanitizeHtml(props.html, {
  ...(props.byTagName && { allowedTags: tagNamePresets[props.byTagName] }),
  ...(props.allowedTags && { allowedTags: props.allowedTags }),
}).replace(/<br \/>/g, '<br>'))
</script>

<template>
  <slot :html="sanitizedHtml" />
</template>
